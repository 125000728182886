export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      "يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو",
      "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"
    ];
  
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear(); // Get the year
  
    return `${day} ${month} ${year}`; // Return formatted date with year
};