import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from '../baseQuery';

export const articlesApi = createApi({
  reducerPath: 'articlesApi',
  baseQuery: baseQuery,
  tagTypes: ['authorArticles', 'allArticles', 'Comments','article'],
  endpoints: (builder) => ({
    createArticle: builder.mutation({
      query: (articleData) => ({
        url: '/articles',
        method: 'POST',
        body: articleData,
      }),
    }),

    getAllArticles: builder.query({
      query: () => '/articles',
      providesTags: ['allArticles'],
    }),
    getAuthorArticles: builder.query({
      query: (authorId) => `/articles/author/${authorId}`,
      providesTags: ['authorArticles'],
    }),

    getArticleById: builder.query({
      query: (id) => `/articles/${id}`,
      providesTags:['article']
    }),

    updateArticle: builder.mutation({
      query: ({ id, ...updatedData }) => ({
        url: `/articles/${id}`,
        method: 'PUT',
        body: updatedData,
      }),
      invalidatesTags: ['authorArticles', 'allArticles'],
    }),

    deleteArticle: builder.mutation({
      query: (id) => ({
        url: `/articles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['authorArticles', 'allArticles'],
    }),
    // filter articles by category
   getArticlesByCategoryId:builder.query({
    query:(categoryId)=>`/articles/category/${categoryId}`,
   }),
    getComments: builder.query({
      query: ({ articleId, filter }) => `/articles/${articleId}/comments?filter=${filter}`,
      providesTags: ['Comments'],
  }),
  
  addComment: builder.mutation({
      query: ({ articleId, comment }) => ({
          url: `/articles/${articleId}/comments`,
          method: 'POST',
          body: { comment },
      }),
      invalidatesTags: ['Comments','allArticles'],
  }),
  // edit comment
  editComment : builder.mutation({
    query: ({ articleId, commentId, comment }) => ({
        url: `/articles/${articleId}/comments/${commentId}`,
        method: 'PUT',
        body: {comment},
    }),
    invalidatesTags: ['Comments'],
  }),
  deleteComment : builder.mutation({
    query: ({ articleId, commentId }) => ({
        url: `/articles/${articleId}/comments/${commentId}`,
        method: 'DELETE',
    }),
    invalidatesTags: ['Comments'],
  }),
  // delete comment
  likeArticle: builder.mutation({
    query: (articleId) => ({
      url: `/articles/${articleId}/like`,
      method: 'POST',
    }),
    invalidatesTags:['allArticles','article']
  }),

  unlikeArticle: builder.mutation({
    query: (articleId) => ({
      url: `/articles/${articleId}/unlike`,
      method: 'POST',
    }),
    invalidatesTags:['allArticles','article']
  }),
  }),
});

export const {
  useCreateArticleMutation,
  useGetArticlesByCategoryIdQuery,
  useGetAllArticlesQuery,
  useDeleteCommentMutation,
  useEditCommentMutation,
  useGetArticleByTopicMutation,
  useGetArticleByIdQuery,
  useUpdateArticleMutation,
  useDeleteArticleMutation,
  useGetAuthorArticlesQuery,
  useGetCommentsQuery,
  useAddCommentMutation,
  useLikeArticleMutation,
  useUnlikeArticleMutation,
} = articlesApi;
