// import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// export const baseApi = createApi({
//   reducerPath: 'api',
//   baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:5132/api' }),
//   endpoints: () => ({}),
// });

// export default baseApi;


import appConfig from "@/config/app.config";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: `${appConfig.baseUrl}/${appConfig.apiPrefix}`,
  prepareHeaders: (headers, { getState }) => {
    // Get the JWT token from your Redux store or wherever you are storing it
    const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", `${token}`);
    }
    return headers;
  },
});

export default baseQuery;